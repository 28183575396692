export default function Hero() {
  const prefix = '+34';
  const phoneNumber = '658 5170 97';

  const message = encodeURIComponent(
    'Hola! Estoy interesado en reservar. Por favor envíenme más información.'
  );

  const mapsLink =
    'https://www.google.com/maps/dir//Av.+Albufereta,+44,+1a,+03016+Alacant/@38.3648387,-0.4728011,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0xd62377c6e18044f:0x47f03e1a7b64569d!2m2!1d-0.4543471!2d38.3648233?entry=ttu';

  return (
    <div className="flex flex-col h-screen justify-between">
      <div className="flex-grow flex items-center justify-center flex-col">
        {/* Logo y texto de la sección Hero */}
        <img src="kokoro.svg" alt="kokoro" className="w-96" />
        <p className="text-xl uppercase mt-8">Próximamente</p>
      </div>
      <div className="p-4 text-center">
        <div className="flex justify-center alaing-center gap-8">
          {/* Botón para llamar por teléfono */}
          <a
            href={`tel:${phoneNumber}`}
            className="inline-flex items-center bg-orange-500 hover:bg-orange-600 hover:scale-110 text-white py-2 px-4 rounded-lg m-2"
          >
            Teléfono: {phoneNumber}
          </a>
          {/* Botón enlace Google Maps */}
          <a
            href={mapsLink}
            className="inline-flex items-center bg-yellow-800 hover:bg-yellow-900 hover:scale-110 text-white py-2 px-4 rounded-lg m-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Av. Albufereta Nº44, 1a Iz. 03016 Alacant
          </a>
        </div>

        <div className="flex justify-end">
          <a
            href={`https://wa.me/${prefix}${phoneNumber}?text=${message}`}
            className="inline-flex items-center bg-green-500 hover:bg-green-600 text-white p-2 rounded-lg m-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="WhatsApp.svg" alt="WhatsApp" className="w-8" />
          </a>
        </div>
        {/* Botón de WhatsApp */}
      </div>
    </div>
  );
}
