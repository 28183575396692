import { React, Fragment } from 'react';
import Hero from './components/Hero';

function App() {
  return (
    <Fragment>
      <header>
        <Hero />
      </header>
    </Fragment>
  );
}

export default App;
